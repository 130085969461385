import { useMeasure } from "@archery-inc/design-system";
import {
  AiRevealAspectRatio,
  AiRevealImagePosition,
} from "../../__generated__/graphql";
import { amplitude } from "../../api/third-party";
import { useMemo, useState } from "react";

export interface RemovedBackgroundFileInfo {
  url: string;
  bucketName: string;
  filePath: string;
}

export type Step =
  | "photo-upload"
  | "prepare-photo"
  | "preset-selection"
  | "generation";

export interface AiState {
  step: Step;
  aspectRatio: AiRevealAspectRatio;
  imagePosition: AiRevealImagePosition;
  margin: number;
  removedBackgroundFileInfo?: RemovedBackgroundFileInfo;
  backgroundColor?: string;
  imageUrl?: string;
  filePath?: string;
  x?: number;
  y?: number;
}

export type Action =
  | {
      type: "remove-background";
      imgData: RemovedBackgroundFileInfo;
    }
  | {
      type: "set-aspect-ratio";
      aspectRatio: AiRevealAspectRatio;
    }
  | {
      type: "set-image-position";
      imagePosition: AiRevealImagePosition;
      x?: number;
      y?: number;
    }
  | {
      type: "set-margin";
      margin: number;
    }
  | {
      type: "img-loaded";
    }
  | {
      type: "set-image-url";
      imageUrl?: string;
      filePath?: string;
    }
  | {
      type: "set-background";
      backgroundColor?: string;
    }
  | {
      type: "validate-image";
    }
  | {
      type: "cancel-image";
    };

export function reducer(state: AiState, action: Action): AiState {
  switch (action.type) {
    case "remove-background":
      return {
        ...state,
        step: "prepare-photo",
        removedBackgroundFileInfo: action.imgData,
        imageUrl: action.imgData.url,
        filePath: action.imgData.filePath,
      };
    case "set-image-url":
      return {
        ...state,
        imageUrl: action.imageUrl,
        filePath: action.filePath,
      };
    case "img-loaded": {
      return {
        ...state,
        margin: 0.15,
        imagePosition: AiRevealImagePosition.Center,
        x: undefined,
        y: undefined,
      };
    }
    case "set-aspect-ratio":
      return {
        ...state,
        aspectRatio: action.aspectRatio,
        imagePosition: AiRevealImagePosition.Center,
        margin: 0.15,
        x: undefined,
        y: undefined,
      };
    case "set-margin": {
      return {
        ...state,
        margin: action.margin,
      };
    }
    case "set-image-position":
      return {
        ...state,
        imagePosition: action.imagePosition,
        x: action.x,
        y: action.y,
      };
    case "validate-image":
      return {
        ...state,
        step: "preset-selection",
      };
    case "set-background":
      return {
        ...state,
        backgroundColor: action.backgroundColor,
      };
    case "cancel-image":
      amplitude.logEvent("Web:Client:AiReveals:PreparePhoto:Close");
      return {
        ...state,
        step: "photo-upload",
      };
  }
}

export function useImagePosition(
  imgPosition: AiRevealImagePosition,
  margin = 0.1,
  currentX?: number,
  currentY?: number,
) {
  const [imgAspectRatio, setImgAspectRatio] = useState(0);
  const [ref, { width, height }] = useMeasure();

  const { x, y, imgW, imgH, minMargin } = useMemo(() => {
    if (!width || !height) {
      return { x: 0, y: 0, imgW: 0, imgH: 0, minMargin: 0 };
    }
    let w = Math.floor((1 - 2 * margin) * width);
    let h = Math.ceil(w / imgAspectRatio);
    let minMarg = 0.5 - (height * imgAspectRatio) / (2 * width);
    if (width > height * imgAspectRatio) {
      h = Math.floor((1 - 2 * margin) * height);
      w = Math.ceil(h * imgAspectRatio);
      minMarg = 0.5 - width / (2 * height * imgAspectRatio);
    }

    const p = 0;
    let left: number;
    let top: number;
    switch (imgPosition) {
      case AiRevealImagePosition.Center:
        [left, top] = [(width - w) / 2, (height - h) / 2];
        break;
      case AiRevealImagePosition.Left:
        [left, top] = [p, (height - h) / 2];
        break;
      case AiRevealImagePosition.Right:
        [left, top] = [width - w - p, (height - h) / 2];
        break;
      case AiRevealImagePosition.Top:
        [left, top] = [(width - w) / 2, p];
        break;
      case AiRevealImagePosition.Bottom:
        [left, top] = [(width - w) / 2, height - h - p];
        break;
      case AiRevealImagePosition.TopLeft:
        [left, top] = [p, p];
        break;
      case AiRevealImagePosition.TopRight:
        [left, top] = [width - w - p, p];
        break;
      case AiRevealImagePosition.BottomLeft:
        [left, top] = [p, height - h - p];
        break;
      case AiRevealImagePosition.BottomRight:
        [left, top] = [width - w - p, height - h - p];
        break;
      case AiRevealImagePosition.Custom:
        [left, top] = [currentX! * width, currentY! * height];
        break;
      default:
        [left, top] = [(width - w) / 2, (height - h) / 2];
        break;
    }
    return {
      x: left / width,
      y: top / height,
      imgW: w,
      imgH: h,
      minMargin: minMarg,
    };
  }, [currentX, currentY, height, imgAspectRatio, imgPosition, margin, width]);

  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    setImgAspectRatio(
      e.currentTarget.naturalWidth / e.currentTarget.naturalHeight,
    );
  };

  return { ref, imgW, imgH, x, y, onImageLoad, width, height, minMargin };
}
