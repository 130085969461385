import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import {
  Button,
  Text,
  IconButton,
  Icon,
  createToast,
  useMediaQuery,
  cn,
  ModalTrigger,
  ModalDialog,
} from "@archery-inc/design-system";
import { useNavigate, useParams } from "react-router-dom";
import {
  getFormatFromAspectRatio,
  useCancelGeneration,
  useCreateProjectFromGeneration,
  useCreateVariation,
  useGenerationQuery,
  useNotifyEmailOnGenerationComplete,
  useSendFeedbackForGeneration,
} from "../../../api/graphql/aiReveals";
import { FormattedMessage, useIntl } from "react-intl";
import { amplitude } from "../../../api/third-party";
import { getDownloadURL } from "firebase/storage";
import { downloadFromUrl, getFileRef } from "../../../api/firebaseStorage";
import ProgressBar from "../../../common/ProgressBar";
import {
  AiAnimatedElementsGenerationStatus,
  GetAiAnimatedElementsGenerationQuery,
  TaskStatus,
} from "../../../__generated__/graphql";
import StarRating from "../../../common/StarRating";
import { AnimatePresence } from "framer-motion";
import { useImagePosition } from "../types";
import { isUUID } from "../../../common/uuid";

export default function AiGenerationCompletePage() {
  const { generationId } = useParams();
  const [generatingInputUrl, setGeneratingInputUrl] = useState<boolean>(false);
  const [generationProgress, setGenerationProgress] = useState<number>(0);
  const navigate = useNavigate();
  const { data, loading, refetch } = useGenerationQuery({
    generationId: generationId!,
  });
  const [cancelGeneration, cancelRequest] = useCancelGeneration();

  const {
    status,
    progress,
    presets = [],
    inputFileUrl,
    notifyEmail,
  } = data?.getAiAnimatedElementsGeneration ?? {};

  const orderedPresets = [
    ...(presets?.filter((p) => p.task?.status === TaskStatus.Completed) ?? []),
    ...(presets?.filter((p) => p.task?.status === TaskStatus.Processing) ?? []),
    ...(presets?.filter(
      (p) =>
        p.task?.status !== TaskStatus.Processing &&
        p.task?.status !== TaskStatus.Completed,
    ) ?? []),
  ];

  const isVariation = presets[0]?.variationId !== "default";

  const allDone = [
    AiAnimatedElementsGenerationStatus.TasksCompleted,
    AiAnimatedElementsGenerationStatus.TasksCompletedWithErrors,
    AiAnimatedElementsGenerationStatus.Error,
    AiAnimatedElementsGenerationStatus.Canceled,
  ].includes(status!);

  function isTaskProcessing(taskStatus?: TaskStatus) {
    return (
      !taskStatus ||
      [
        TaskStatus.Queued,
        TaskStatus.Pending,
        TaskStatus.Starting,
        TaskStatus.Processing,
      ].includes(taskStatus)
    );
  }

  const shouldFetch =
    !data?.getAiAnimatedElementsGeneration?.presets
      ?.map(({ task }) => !!task)
      .filter((v) => !!v).length ||
    data?.getAiAnimatedElementsGeneration?.presets?.filter(({ task }) =>
      isTaskProcessing(task?.status),
    ).length > 0;

  useEffect(() => {
    let intervalRef = 0;
    if (shouldFetch) {
      intervalRef = setInterval(() => {
        amplitude.logEvent("Web:Client:AiReveals:Generation:Poll", {
          generationId: generationId!,
          isVariation,
        });
        void refetch();
      }, 3000) as unknown as number;

      return () => {
        document.title = "Mojo";
        clearInterval(intervalRef);
      };
    } else {
      if (intervalRef) clearInterval(intervalRef);
      setGenerationProgress(100);
    }
  }, [allDone]);

  useEffect(() => {
    if (progress !== 100 && progress && shouldFetch && !isNaN(progress)) {
      document.title = `${Math.trunc(progress)}%`;
      setGenerationProgress(progress);
    } else {
      document.title = "Mojo";
    }
  }, [progress]);

  async function switchPreset() {
    amplitude.logEvent("Web:Client:AiReveals:SwitchPreset", {
      generationId: data?.getAiAnimatedElementsGeneration.generationId,
      isVariation,
    });
    setGeneratingInputUrl(true);
    const { bucketName, filePath } =
      data?.getAiAnimatedElementsGeneration.inputBucketInfo ?? {};
    const inputFileRef = getFileRef(bucketName!, filePath!);
    const url = await getDownloadURL(inputFileRef);
    navigate(`/ai-reveals/create${location.search}`, {
      state: {
        removedBackgroundFileInput: { url, bucketName, filePath },
        aspectRatio:
          data?.getAiAnimatedElementsGeneration.presets[0].aspectRatio,
        imagePosition:
          data?.getAiAnimatedElementsGeneration.presets[0].imagePosition,
      },
    });
  }

  async function cancel() {
    if (!generationId) return;
    amplitude.logEvent("Web:Client:AiReveals:Generation:Cancel", {
      generationId,
      isVariation,
    });
    await cancelGeneration({ variables: { generationId } });
  }

  function shouldDisplayFeedback() {
    return (
      allDone &&
      presets.some((preset) => preset.task?.status === TaskStatus.Completed)
    );
  }

  const presetsComponent = orderedPresets.map((preset, delayIndex) => (
    <Preset
      key={preset.presetId + preset.seed}
      generationId={generationId!}
      preset={preset}
      delayIndex={delayIndex}
      previewUrl={inputFileUrl}
    />
  ));

  return (
    <div className="flex flex-col items-center h-full w-full">
      <div className="w-full flex justify-end">
        <IconButton
          iconClassName="size-6 right-10 z-50"
          color="transparent"
          icon="close"
          onPress={() => {
            navigate(`/ai-reveals/my-ai-generations${location.search}`);
          }}
        />
      </div>

      <div className="flex flex-col items-center px-4">
        {allDone ? (
          <Text
            elementType="h1"
            weight="medium"
            variant="title-1"
            className="text-center"
            style={{ lineHeight: "110%" }}
          >
            {status === AiAnimatedElementsGenerationStatus.TasksCompleted ? (
              <FormattedMessage id="ai_reveal_video_successfully_generated" />
            ) : status === AiAnimatedElementsGenerationStatus.Canceled ? (
              <FormattedMessage id="ai_reveal_generation_canceled" />
            ) : (
              <FormattedMessage id="ai_reveal_generation_error_message" />
            )}
          </Text>
        ) : loading ? (
          <div className="flex justify-between items-center">
            <Text
              elementType="h1"
              weight="medium"
              variant="title-1"
              className="text-center p-4"
              style={{ lineHeight: "110%" }}
            >
              <FormattedMessage id="ai_reveal_loading_generation" />
            </Text>
            <Icon icon="spinner" size="md" />
          </div>
        ) : (
          <div className="p-5 flex items-center flex-col">
            <Text
              elementType="h1"
              weight="medium"
              variant="title-1"
              className="text-center"
              style={{ lineHeight: "110%" }}
            >
              <FormattedMessage id="ai_reveal_generating_video_txt" />
            </Text>
            <Text className="max-w-xl leading-15 text-center">
              <FormattedMessage id="ai_reveal_waiting_msg" />
            </Text>
            <div className="flex gap-2">
              <NotifyMe isVisible={!notifyEmail} />
              <Button
                size="l"
                color="red"
                onPress={() => {
                  void cancel();
                }}
                isDisabled={cancelRequest.loading}
                leftIcon={cancelRequest.loading ? "spinner" : "close"}
              >
                {cancelRequest.loading ? (
                  <FormattedMessage id="ai_reveal_canceling_txt" />
                ) : (
                  <FormattedMessage id="common_cancel" />
                )}
              </Button>
            </div>
          </div>
        )}
        {allDone && (
          <div className="sm:static fixed w-full bottom-0 sm:border-t-0 border-t-1 border-grey-4 bg-white z-30 py-3 flex flex-wrap justify-center gap-3 mt-7">
            <Button
              color="grey"
              leftIcon={generatingInputUrl ? "spinner" : "reload"}
              isDisabled={generatingInputUrl}
              className="px-3 bg-grey-3 hover:bg-grey-4 whitespace-nowrap"
              onPress={() => void switchPreset()}
            >
              <FormattedMessage id="ai_reveal_switch_preset_txt" />
            </Button>
            {!data?.getAiAnimatedElementsGeneration.presets[0].customPrompt && (
              <VariationButton
                generationId={generationId}
                isVariation={isVariation}
                presetId={
                  data?.getAiAnimatedElementsGeneration.presets[0].presetId
                }
              />
            )}
          </div>
        )}
        {shouldDisplayFeedback() ? (
          <Feedback
            presetId={data!.getAiAnimatedElementsGeneration.presets[0].presetId}
            generationId={data!.getAiAnimatedElementsGeneration.generationId}
            rating={data!.getAiAnimatedElementsGeneration.feedback?.rating}
          />
        ) : null}
      </div>
      <ul className="flex gap-4 flex-1 px-2 justify-center w-full pb-36 mt-4">
        <div className="flex items-center flex-col w-full">
          {presetsComponent}
          {!allDone && (
            <div className="h-2 sm:w-60 md:w-72 xl:w-80 w-4/5 mt-2">
              <ProgressBar progress={generationProgress} />
            </div>
          )}
        </div>
      </ul>
    </div>
  );
}

function VariationButton({
  generationId,
  isVariation,
  presetId,
}: {
  generationId?: string;
  isVariation?: boolean;
  presetId?: string;
}) {
  const [createVariation, { loading: creatingVariation }] =
    useCreateVariation();
  const navigate = useNavigate();

  async function generateVariation() {
    if (!generationId) return;
    amplitude.logEvent("Web:Client:AiReveals:GenerateVariation", {
      generationId,
      isVariation,
      presetId,
    });
    const result = await createVariation({ variables: { generationId } });
    const newGenerationId = result.data?.createGenerationVariation.generationId;
    if (newGenerationId) {
      navigate(`/ai-reveals/generations/${newGenerationId}${location.search}`);
    }
  }

  return (
    <ModalTrigger>
      <Button leftIcon="plus" className="px-3 whitespace-nowrap">
        <FormattedMessage id="ai_reveal_generate_variation" />
      </Button>
      <ModalDialog className="flex flex-col gap-3 p-5 relative max-w-lg">
        {({ close }) => (
          <>
            <IconButton
              className="absolute top-2 right-2"
              icon="close"
              color="grey"
              onPress={close}
            />
            <Text weight="semibold" className="text-lg">
              <FormattedMessage id="ai_reveal_generate_variation" />
            </Text>
            <Text>
              <FormattedMessage id="ai_reveal_generate_variation_desc" />
            </Text>
            <div className="flex gap-2 justify-center">
              <Button type="button" color="grey" onPress={close}>
                <FormattedMessage id="common_cancel" />
              </Button>
              <Button
                color="black"
                leftIcon={creatingVariation ? "spinner" : undefined}
                isDisabled={creatingVariation}
                onPress={() => void generateVariation()}
              >
                <FormattedMessage id="label_confirm" />
              </Button>
            </div>
          </>
        )}
      </ModalDialog>
    </ModalTrigger>
  );
}

function NotifyMe({ isVisible }: { isVisible: boolean }) {
  const { generationId } = useParams();
  const [notify, { loading }] = useNotifyEmailOnGenerationComplete(
    generationId!,
  );

  async function notifyMe() {
    await notify();
    createToast(<FormattedMessage id="ai_reveal_notify_me_sent" />);
    amplitude.logEvent("Web:Client:AiReveals:NotifyMe", {
      generationId,
    });
    localStorage.setItem("ai_reveal_notify_me", "true");
  }

  useEffect(() => {
    if (localStorage.getItem("ai_reveal_notify_me")) {
      void notifyMe();
    }
  }, []);

  if (!isVisible) {
    return null;
  }

  return (
    <Button
      onPress={() => void notifyMe()}
      color="grey"
      size="l"
      isDisabled={loading}
      leftIcon={loading ? "spinner" : "mail"}
    >
      <FormattedMessage id="ai_reveal_notify_me_txt" />
    </Button>
  );
}

interface FeedbackProps {
  presetId: string;
  generationId: string;
  rating?: number;
}

function Feedback({
  presetId,
  generationId,
  rating: initialRating = 0,
}: FeedbackProps) {
  const intl = useIntl();
  const [formVisible, setFormVisible] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [rating, setRating] = useState(initialRating);
  const [frozen, setFrozen] = useState(initialRating !== 0);
  const [updateGenerationFeedback] = useSendFeedbackForGeneration();

  const handleRatingChange = (newRating: number) => {
    amplitude.logEvent("Web:Client:AiReveals:Feedback:StarRating:StarClicked", {
      rating: newRating,
      presetId,
      generationId,
    });
    setFormVisible(true);
    setRating(newRating);
    void updateGenerationFeedback({
      variables: { generationId, rating: newRating, message: feedback || null },
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    amplitude.logEvent("Web:Client:AiReveals:Feedback:Form:Submited", {
      presetId,
      generationId,
      rating,
    });
    setFormVisible(false);
    setFrozen(true);
    void updateGenerationFeedback({
      variables: { generationId, rating, message: feedback || null },
    });

    createToast(
      <p>
        <FormattedMessage id="ai_reveal_feedback_thanks_toast_msg" />
      </p>,
    );
  };

  function getPlaceholder() {
    switch (rating) {
      case 1:
        return intl.formatMessage({ id: "ai_reveal_feedback_msg_one" });
      case 2:
        return intl.formatMessage({ id: "ai_reveal_feedback_msg_two" });
      case 3:
        return intl.formatMessage({ id: "ai_reveal_feedback_msg_three" });
      case 4:
        return intl.formatMessage({ id: "ai_reveal_feedback_msg_four" });
      case 5:
        return intl.formatMessage({ id: "ai_reveal_feedback_msg_five" });
      default:
        return intl.formatMessage({ id: "ai_reveal_feedback_msg_three" });
    }
  }

  return (
    <div>
      <div className="flex pb-0 p-4 items-center justify-center">
        <p className="p-1">
          {frozen ? "Your video rating:" : "Rate your video:"}
        </p>
        <StarRating
          rating={rating}
          frozen={frozen}
          onRatingChange={(newRating) => void handleRatingChange(newRating)}
        />
      </div>
      {formVisible && (
        <form className="p-4 flex flex-col" onSubmit={handleSubmit}>
          <textarea
            className="w-full h-40 p-2 bg-grey-2 border rounded resize-none focus:outline-none focus:border-tangerine"
            placeholder={getPlaceholder()}
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
          />
          <Button className="m-2" size="s" type="submit">
            <FormattedMessage id="send_feedback_txt" />
          </Button>
        </form>
      )}
    </div>
  );
}

interface PresetProps {
  delayIndex: number;
  generationId: string;
  previewUrl?: string;
  preset: GetAiAnimatedElementsGenerationQuery["getAiAnimatedElementsGeneration"]["presets"][0];
}

function Preset({ generationId, preset, delayIndex, previewUrl }: PresetProps) {
  const [generateProject, { loading: generatingProject }] =
    useCreateProjectFromGeneration();
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  const isMobile = useMediaQuery("sm");
  const videoUrl = `https://storage.googleapis.com/mojo-public-files/assets/ai/previews/${preset?.presetId}-result.mp4`;

  function download() {
    if (!preset.task) return;
    amplitude.logEvent("Web:Client:AiReveals:Download", {
      index: delayIndex,
      presetId: isUUID(preset.presetId) ? "custom" : preset.presetId,
      dbPresetId: preset.presetId,
      isVariation: preset.variationId !== "default",
    });
    const results = preset.task.parsedResult as string[];
    void downloadFromUrl(
      results[results.length - 1],
      `${preset.presetId}-${new Date().getTime()}-${delayIndex + 1}`,
    );
  }

  async function createProject() {
    await generateProject({
      variables: { generationId },
    });
    amplitude.logEvent("Web:Client:AiReveals:CreateProjectFromGeneration", {
      generationId,
      presetId: preset.presetId,
      from: "generationPage",
      isVariation: preset.variationId !== "default",
    });
    navigate(`/my-projects${location.search}`);
  }

  const {
    ref,
    imgW,
    imgH,
    x,
    y,
    width: w,
    height: h,
    onImageLoad,
  } = useImagePosition(
    preset.imagePosition,
    preset.margin ?? undefined,
    preset.coordinates?.x,
    preset.coordinates?.y,
  );

  if (!preset) {
    return null;
  }

  const aspectRatio = getFormatFromAspectRatio(preset.aspectRatio);
  const [width, height] = aspectRatio.split("/").map(Number);
  const result = preset.task?.parsedResult as string[];
  const isTaskProcessing =
    !preset?.task?.status ||
    [
      TaskStatus.Queued,
      TaskStatus.Pending,
      TaskStatus.Starting,
      TaskStatus.Processing,
    ].includes(preset.task?.status);

  return (
    <li
      style={{ aspectRatio }}
      className={cn(
        "inline-block w-full",
        width > height ? "max-w-2xl" : "max-w-md",
      )}
    >
      {preset?.task?.status === TaskStatus.Completed ? (
        <div
          className="relative overflow-hidden"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <video
            src={result[result?.length - 1]}
            autoPlay
            loop
            muted
            playsInline
            className="rounded-3 w-full"
          />
          {isMobile ? (
            <span className="absolute flex gap-2 bottom-2 right-0 w-full px-2">
              <Button
                leftIcon={generatingProject ? "spinner" : "projects_outline"}
                isDisabled={generatingProject}
                color="black"
                className="border-grey-2 border-3 w-full px-0 whitespace-nowrap"
                onPress={() => void createProject()}
              >
                <FormattedMessage id="ai_reveal_use_in_project" />
              </Button>
              <Button
                leftIcon="download"
                color="black"
                className="border-grey-2 border-3 w-full px-0"
                onPress={download}
              >
                <FormattedMessage id="share_video_download" />
              </Button>
            </span>
          ) : (
            <AnimatePresence>
              {isHovered && (
                <motion.span
                  className="absolute bottom-2 flex w-full flex-col px-6 mb-2"
                  animate={{ opacity: 1 }}
                  initial={{ opacity: 0 }}
                  exit={{ opacity: 0 }}
                >
                  <Button
                    leftIcon={
                      generatingProject ? "spinner" : "projects_outline"
                    }
                    isDisabled={generatingProject}
                    color="black"
                    className="border-grey-2 border-3 mb-2 whitespace-nowrap"
                    onPress={() => void createProject()}
                  >
                    <FormattedMessage id="ai_reveal_use_in_project" />
                  </Button>
                  <Button
                    leftIcon="download"
                    color="black"
                    className="border-grey-2 border-3 whitespace-nowrap"
                    onPress={download}
                  >
                    <FormattedMessage id="share_video_download" />
                  </Button>
                </motion.span>
              )}
            </AnimatePresence>
          )}
        </div>
      ) : (
        <motion.div
          className="relative rounded-3 overflow-hidden bg-grey-3"
          style={{ aspectRatio }}
        >
          {preset?.task?.status === TaskStatus.Failed && (
            <div className="text-black absolute bottom-0 left-0 right-0 transform bg-error-red p-3 z-10 flex justify-between items-center ">
              <IconButton icon="close" color="transparent" />
              <p>
                <FormattedMessage id="something_went_wrong_msg" />
                <br />
                <FormattedMessage id="ai_reveal_video_failed_to_generate_msg" />
              </p>
            </div>
          )}
          <div
            ref={ref}
            className="relative h-full flex items-center justify-center"
          >
            {preset?.task?.status === TaskStatus.Processing && (
              <video
                className="w-full h-auto absolute top-0 left-0 blur"
                controls={false}
                autoPlay
                playsInline
                loop
                muted
                src={videoUrl}
              />
            )}
            <img
              src={previewUrl}
              onLoad={onImageLoad}
              className={cn("absolute", { blur: isTaskProcessing })}
              style={{
                left: x !== undefined && w ? x * w : undefined,
                top: y !== undefined && h ? y * h : undefined,
                width: imgW,
                height: imgH,
              }}
            />
            <div className="absolute bg-white/50 rounded-2 bottom-2 px-3 py-2">
              <p className="text-2xs text-center">
                {preset?.task?.status === TaskStatus.Pending && (
                  <FormattedMessage id="ai_reveal_high_demand_message" />
                )}
                {preset?.task?.status === TaskStatus.Processing && (
                  <FormattedMessage id="ai_reveal_video_processing_message" />
                )}
                {preset?.task?.status === TaskStatus.Canceled && (
                  <FormattedMessage id="ai_reveal_generation_canceled" />
                )}
              </p>
            </div>
          </div>
          {isTaskProcessing && (
            <motion.div
              className="w-full h-1 bg-white absolute top-full left-0 z-30"
              animate={{ top: "0%" }}
              transition={{
                repeat: Infinity,
                repeatType: "mirror",
                type: "spring",
                mass: 1,
                stiffness: 150,
                damping: 30,
                repeatDelay: 1,
                delay: 0.2 * delayIndex,
              }}
            />
          )}
        </motion.div>
      )}
    </li>
  );
}
